<template>
  <ContainerBasic container-type="PAGE">
    <div class="relative text-center">
      <div
        class="absolute h-full md:w-[150px] lg:w-[180px] xl:w-[245px] hidden md:inline-block"
        :class="
          blockData.orientation === ORIENTATION_IMAGE_TEXT
            ? '-translate-x-full'
            : ''
        "
      >
        <div
          class="relative h-full"
          :class="
            blockData.orientation === ORIENTATION_IMAGE_TEXT
              ? 'scale-x-[-1]'
              : ''
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 245 500"
            preserveAspectRatio="none"
          >
            <g id="devider" transform="translate(20167 13673)">
              <path
                id="bg"
                d="M0,0H228L13,500H0Z"
                transform="translate(-20167 -13673)"
                :fill="getAreaColour()"
                stroke="none"
              />
              <path
                id="triangle"
                d="M114.5,0,229,266H0Z"
                transform="translate(-20165 -13439)"
                fill="#fff"
                stroke="none"
                opacity="0.4"
              />
              <path
                id="devider"
                d="M215,0h30L30,500H0Z"
                transform="translate(-20167 -13673)"
                fill="var(--thm-primary-base)"
                stroke="none"
              />
            </g>
          </svg>
        </div>
      </div>
      <div
        class="flex min-h-[500px] flex-col"
        :class="
          blockData.orientation === ORIENTATION_IMAGE_TEXT
            ? 'md:flex-row'
            : 'md:flex-row-reverse'
        "
      >
        <div
          class="flex items-end bg-cover basis-1/2 aspect-video md:aspect-auto"
          :class="
            blockData.orientation === ORIENTATION_IMAGE_TEXT
              ? 'justify-start'
              : 'justify-end'
          "
          :style="`background-image: url(${image.url})`"
        >
          <div
            v-if="image.copyright !== null"
            class="max-w-[180px] top-[180px] m-sm"
          >
            <span
              class="text-sm italic font-light text-white drop-shadow-xs font-roboto leading-xs"
              >&copy; {{ image.copyright }}</span
            >
          </div>
        </div>
        <div
          class="flex flex-col items-center basis-1/2"
          :class="[sectionBGColor, hasAlphaBG ? 'text-vdv-font' : 'text-white']"
          :style="'background-color: ' + getAreaColour()"
        >
          <div class="md:hidden h-[15px] w-full bg-primary-base" />
          <div
            class="md:pl-0 md:pr-md lg:px-[8%] xl:px-[12%] py-md md:pt-xl md:pb-lg"
          >
            <Headline
              v-if="blockData.headline"
              :headline="blockData.headline"
              level="h2"
              class="mb-sm"
              :class="[
                hasAlphaBG
                  ? 'fill-vdv-base stroke-vdv-border-dark'
                  : 'fill-white stroke-white',
              ]"
              :has-decoration="true"
            />
            <WYSIWYGContentRaw
              :content="blockData.text"
              inner-classes="wysi-small-text text-center"
            ></WYSIWYGContentRaw>

            <div v-if="blockData?.topCta" class="mt-auto pt-sm">
              <CallToAction :block-data="blockData.topCta"></CallToAction>
            </div>
            <div
              class="flex items-center justify-center flex-auto w-full py-xs"
            >
              <div
                class="w-[110px] border-solid border-b-1 mr-sm"
                :class="[
                  hasAlphaBG
                    ? 'border-b-vdv-border-dark border-vdv-border-dark'
                    : 'border-white border-b-white',
                ]"
              />
              <Icon :icon="getAreaIcon()" classes="!w-6 !h-6" />
              <div
                class="w-[110px] border-solid border-b-1 ml-sm"
                :class="[
                  hasAlphaBG
                    ? 'border-b-vdv-border-dark border-vdv-border-dark'
                    : 'border-white border-b-white',
                ]"
              />
            </div>
            <Headline :headline="getAreaTitle()" level="h2" class="mb-sm" />
            <div class="px-md">
              <p>{{ getAreaDescription() }}</p>
            </div>
            <div v-if="blockData?.bottomCta">
              <CallToAction
                :block-data="blockData.bottomCta"
                class="mb-0 mt-sm"
              ></CallToAction>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ContainerBasic>
</template>

<script setup lang="ts">
import CallToAction from '~~/src/components/block/cta/vdv/CallToAction.vue';
import ContainerBasic from '@/components/components/container-basic.vue';
import { Headline, WYSIWYGContentRaw, Icon } from '~/complib';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import type { ContentTeaserData } from './blockType';
import { ORIENTATION_IMAGE_TEXT } from './utils';
import { useAreaStore } from '~/stores/useArea';

const isMobile = inject(mq_breakpointIsMobile);

const props = defineProps({
  blockData: {
    type: Object as PropType<ContentTeaserData>,
    required: true,
    default: {} as ContentTeaserData,
  },
});

const image = computed(() =>
  isMobile.value ? props.blockData.image.mobile : props.blockData.image.desktop,
);

const areaStore = useAreaStore();
const area = areaStore.getActiveArea;
const hasAlphaBG = props.blockData.bgColor?.value === 'areaColorAlpha';

const sectionBGColor = computed(() => {
  return props.blockData.area?.color
    ? `bg-[${props.blockData.area.color}]`
    : 'bg-activesection-base';
});

function getAreaTitle(): string {
  return props.blockData.area?.title || area?.title;
}

function getAreaDescription(): string {
  return props.blockData.area?.description || area?.description;
}

function getAreaColour(): string {
  if (props.blockData.bgColor?.value === 'areaColorAlpha') {
    return props.blockData.area?.alpha
      ? props.blockData.area?.alpha
      : props.blockData.area?.color.toString() + '4D';
  } else {
    return props.blockData.area?.color;
  }
}

function getAreaIcon() {
  return props.blockData.area?.icon || area?.icon;
}
</script>
